
import { Col, Form, Row, Space, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AssetImage } from '../../assets/image/Index'
import { Vector } from '../../assets/svg/Index'
import { InputForm } from '../../components/form/InputForm'
import { Color } from '../../styles/Color'
import { MenuType } from '../../types/MenuType'
import { 상표검색타입 } from '../../types/상표검색타입'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { 앱다운로드 } from '../etc/앱다운로드'
import LocalStorageUtility from '../../utils/LocalStorageUtility'

export const 상표검색: FunctionComponent = () => {
    const [type, setType] = useState<상표검색타입.타입>(상표검색타입.타입.All)

    const [form] = Form.useForm()
    const location = useLocation()
    const navigate = useNavigate()

    const { state } = location

    const types = [상표검색타입.타입.All, 상표검색타입.타입.상표, 상표검색타입.타입.출원인]

    useEffect(() => {
        const userData = LocalStorageUtility.get('user')
        if (!userData) {
            alert('로그인 후 진행해주세요.')
            window.location.href = '/login'
        }

        if(state) {
            form.setFieldValue('keyword', state.keyword)
        }
    }, [])

    return (
        <Container menu={MenuType.상표검색}>
            <Row className='service-container'>
                <Col span={24} className='header'>
                    <LayoutContainer>
                        <Col span={12} style={{ alignSelf: 'center', marginTop: 100, }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`나혼자상표에 등록된`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`상표를 검색해 보세요.`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Gray10, }}>{`어떤 상표들이 등록되어 있을까?`}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ marginTop: 40, alignSelf: 'end', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.search.상표검색}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ paddingBottom: 80, }}>
                    <LayoutContainer style={{ marginTop: 40 }}>
                        <Col span={24} style={{ marginTop: 50, textAlign: 'center', }}>
                            <Space size={40} split={'|'}>
                                {types.map((t, idx: number) => {
                                    return (
                                        <Typography.Link
                                            key={t}
                                            onClick={() => { setType(t) }}
                                        >
                                            <Typography.Text
                                                strong={t === type}
                                                style={{
                                                    fontSize: 26,
                                                }}
                                            >
                                                {상표검색타입.displayName(t)}
                                            </Typography.Text>
                                        </Typography.Link>
                                    )
                                })}
                            </Space>
                        </Col>
                        <Col span={24} style={{ marginTop: 10, }}>
                            <Row justify={'center'}>
                                <Col span={12}>
                                    <Form form={form}>
                                        <InputForm
                                            name='keyword'
                                            placeholder='상표명을 입력하세요'
                                            style={{
                                                height: 50,
                                                borderRadius: 30,
                                                fontSize: 16,
                                                paddingLeft: 30,
                                            }}
                                            layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                                            onPressEnter={() => {
                                                const keyword = form.getFieldValue('keyword')
                                                if(!keyword || keyword.trim().length <= 0) {
                                                    alert('검색어를 입력하세요')
                                                    return
                                                }

                                                navigate('/search/trademarks', { state: { keyword: keyword, searchType: type } })
                                            }}
                                        />
                                    </Form>
                                    <Typography.Link
                                        onClick={() => {
                                            const keyword = form.getFieldValue('keyword')
                                            if(!keyword || keyword.trim().length <= 0) {
                                                alert('검색어를 입력하세요')
                                                return
                                            }
                                            navigate('/search/trademarks', { state: { keyword: keyword, searchType: type } })
                                        }}
                                    >
                                        <img
                                            src={Vector.icon.search.gray} width={30}
                                            style={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 20,
                                                zIndex: 10,
                                            }}
                                        />
                                    </Typography.Link>
                                </Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
                {/* <Col span={24} style={{ marginTop: 60, paddingTop: 40, paddingBottom: 40, }}>
                    <LayoutContainer style={{ justifyContent: 'center', }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Link to={''}>
                                <img width={'100%'} src={AssetImage.banner.상표검색.지정상품} alt={'banner'} />
                            </Link>
                        </Col>
                    </LayoutContainer>
                </Col> */}
                <Col
                    span={24}
                    style={{
                        marginTop: 30,
                        backgroundColor: Color.Blue80,
                        paddingTop: 50,
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}>
                    <앱다운로드 />
                </Col>
            </Row>
        </Container>
    )
}