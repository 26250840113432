import { Button, Col, Dropdown, MenuProps, Row, Space, Typography } from 'antd';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Vector } from '../../assets/svg/Index';
import { UserModel } from '../../models/UserModel';
import { MenuType } from '../../types/MenuType';
import { NavigationType } from '../../types/NavigationType';
import LocalStorageUtility from '../../utils/LocalStorageUtility';
import { LayoutContainer } from './LayoutContainer';

interface IProps {
    menu?: MenuType
    type: NavigationType
    children?: ReactNode
}

export const Navigation: FunctionComponent<IProps> = (props: IProps) => {
    const {
        menu,
        type,
        children
    } = props
    const [show, setShow] = useState<boolean>(false)
    const [user, setUser] = useState<UserModel.IUserModel>()

    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener("scroll", handleNavbar);
        return () => {
            window.removeEventListener("scroll", handleNavbar);
        }
    }, [])

    const handleNavbar = () => {
        setShow(window.pageYOffset > 100)
    }

    const logo = () => {
        switch (type) {
            case NavigationType.White:
                return Vector.logo.blue
            case NavigationType.Transparent:
            default:
                return show ? Vector.logo.blue : Vector.logo.white

        }
    }

    const className = () => {
        switch (type) {
            case NavigationType.White:
                return 'white-navigation-container'
            case NavigationType.Transparent:
            default:
                return ''

        }
    }

    const menus = [
        { type: MenuType.회사소개, display: '회사 소개', url: '/company', },
        { type: MenuType.서비스안내, display: '서비스 안내', url: '/service', },
        { type: MenuType.상표등록, display: '상표 등록', url: '/create/trademarks', },
        { type: MenuType.상표검색, display: '상표 검색', url: '/search', },
        { type: MenuType.절차안내, display: '절차 안내', url: '/process', },
        { type: MenuType.QnA, display: 'Q&A', url: '/qna', },
    ]

    useEffect(() => {
        const userData = LocalStorageUtility.get('user')
        if (userData) {
            try {
                setUser(JSON.parse(userData))
            } catch(e: any) {
                console.log('error', e)
            }
        }
    }, [])

    const items: MenuProps['items'] = [
        {
            key: '2',
            label: (
                <Link to={'/user/trademark-process'}>출원 진행 현황</Link>
            ),
        },
        {
            key: '3',
            label: (
                <a
                    onClick={() => {
                        LocalStorageUtility.remove('user')
                        window.location.href = '/'
                    }}
                >
                    로그아웃
                </a>
            ),
        },
    ];

    return (
        <Row className={`navigation-container ${show ? 'sticky-navigation-container' : ''} ${className()}`}>
            <Col span={24} style={{ alignSelf: 'center' }}>
                <LayoutContainer>
                    <Col span={24}>
                        <Row align='middle'>
                            <Col span={3}>
                                <Link to={'/'}>
                                    <img src={logo()} alt='logo' />
                                </Link>
                            </Col>
                            <Col span={21} style={{ textAlign: 'end' }}>
                                <Space size={40}>
                                    {menus.map((m) => {
                                        const strong = m.type === menu
                                        return (
                                            <Link
                                                key={m.type}
                                                to={{ pathname: m.url }}
                                            >
                                                <Typography.Text
                                                    strong={strong}
                                                >
                                                    {m.display}
                                                </Typography.Text>
                                            </Link>
                                        )
                                    })}
                                    {user && (
                                        <Dropdown menu={{
                                            items
                                        }}>
                                            <Button>
                                                {user.fullName ?? '사용자'}
                                            </Button>
                                        </Dropdown>
                                    )}
                                    {!user && (
                                        <Button
                                            onClick={() => { navigate('/login') }}
                                            className={`${menu === MenuType.로그인 ? 'activated' : ''}`}
                                        >
                                            로그인/회원가입
                                        </Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    {children}
                </LayoutContainer>
            </Col>
        </Row>
    )
}