import { Col, Row, Space, Typography } from 'antd'
import { Fragment, FunctionComponent, useState } from 'react'
import { AssetImage } from '../../assets/image/Index'
import { Color } from '../../styles/Color'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { MenuType } from '../../types/MenuType'

type QnaType = '전체' | '상표' | '도메인'

export const Qna: FunctionComponent = () => {
    const [type, setType] = useState<QnaType>('전체')

    const types: Array<QnaType> = ['전체', '상표', '도메인']

    const contents = [
        {
            type: '상표',
            title: '상표 등록! 왜? 꼭? 받아야 하나요?',
            typographies: [
                <Typography.Text>브랜드가 유명해지면 그 브랜드를 모방하려는 사람들이 생기기 때문에 상표등록은 브랜드를 보호하기 위해 반드시 필요한 절차입니다.</Typography.Text>,
                <Typography.Text>제 3자가 고객님의 상표와 같거나 비슷한 것을 먼저 등록해 버리면 고객님은 상표의 사용을 포기하거나 또는 상표등록을 받은 자에게 일정 로열티를 지불하고 라이선스를 받아 사용해야만 하는 경우가 생길 수 있습니다.</Typography.Text>,
                <Typography.Text>
                    <Typography.Text strong>상표를 등록</Typography.Text>하면 이러한 염려 없이 <Typography.Text strong>안정적으로 상표를 사용</Typography.Text>할 수 있습니다.
                </Typography.Text>
            ]
        },
        {
            type: '상표',
            title: '상표 출원까지 얼마나 걸리나요?',
            typographies: [
                <Typography.Text>상표 등록을 위해서는 몇 가지 단계를 통과해야 하며, 기본적으로 <Typography.Text strong>상표출원-심사-상표출원공고 결정-상표등록 결정</Typography.Text> 과정을 거쳐 진행 됩니다. </Typography.Text>,
                <Typography.Text>큰 문제없이 등록까지 이어지는 경우, 평균적으로 <Typography.Text strong>특허청 심사 10~12개월, 출원공고결정 3개월, 상표 등록 결정 2개월 정도</Typography.Text>의 기간이 소요되며, 우선 심사로 진행하는 경우 특허청 심사 기간이 2~3개월 정도 소요됩니다. </Typography.Text>,
                <Typography.Text>※ 최근 상표에 대한 관심이 늘어남에 따라 상표 출원 건이 급격하게 증가한 반면, 심사관 수는 한정되어 있어 1년 이상 소요 되는 경우가 많습니다. </Typography.Text>,
                <Typography.Text>※ 특허청 심사에서 거절 의견이 나오거나 제 3자의 이의 제기가 있는 경우 기간이 더 길어 집니다.</Typography.Text>,
            ]
        },
        {
            type: '상표',
            title: '상표등록 신청하면 100% 등록되나요?',
            typographies: [
                <Typography.Text>상표등록 여부는 특허청 심사관이 판단하며, 100% 등록은 보장되지 않습니다.</Typography.Text>,
            ]
        },
        {
            type: '상표',
            title: '한글 상표, 영문 상표 어떤 걸로 해야 좋은가요?',
            typographies: [
                <Typography.Text>{`1) 한글도 자주 사용하고 영문도 자주 사용하는 경우 : 한글, 영문 각각 상표등록`}</Typography.Text>,
                <Typography.Text>{`2) 한글을 주로 사용하는 경우 : 한글로 상표등록`}</Typography.Text>,
                <Typography.Text>{`3) 영어를 주로 사용하거나 해외 상표등록 계획이 있는 경우 : 영문으로 상표등록`}</Typography.Text>,
                <Typography.Text>{`4) 한글 발음과 영문 발음이 동일하거나 항상 한영을 같이 사용할 경우 : 한글과 영문을 병기(나란히 적는 것)하여 상표등록`}</Typography.Text>,
            ]
        },
        {
            type: '상표',
            title: '상표 진행 상황을 알고 싶습니다.',
            typographies: [
                <Typography.Text>{`이미 상표를 출원하셨다면, 아래 사이트에서 진행 상황을 실시간으로 확인하실 수 있습니다. `}</Typography.Text>,
                <Typography.Text>{`※ 상표 출원 후 15일 정도 후에 특허청에 업데이트되어 조회됩니다.`}</Typography.Text>,
                <Typography.Link href='http://www.kipris.or.kr/khome/main.jsp' target='_blank'>{`🏷️ 특허청 키프리스에서 조회 >`}</Typography.Link>,
                <Typography.Link href='https://markinfo.co.kr/front/phtml/index.php' target='_blank'>{`🏷️ 마크인포에서 조회 >`}</Typography.Link>,
            ]
        },
        {
            type: '상표',
            title: '출원하고 나중에 수정할 수 있나요?',
            typographies: [
                <Typography.Text>수정하실 수 없습니다. 부득이하게 수정해야 한다면 상표출원을 취하한 후 재출원을 진행해야 합니다.</Typography.Text>,
            ]
        },
        {
            type: '상표',
            title: '먼저 신청했는데 다른 사람이 우선심사로 신청하면 어떻게 되나요?',
            typographies: [
                <Typography.Text>{`우선심사 제도는 심사 대기 기간을 줄여 주는 제도입니다. 상표등록은 선출원 주의로서 먼저 신청한 사람에게 우선권이 주어집니다. `}</Typography.Text>,
                <Typography.Text>{`나중에 신청한 사람이 우선심사를 신청하더라도 먼저 신청한 사람에게 우선권을 가집니다.`}</Typography.Text>,
            ]
        },
        {
            type: '도메인',
            title: '.app / .dev / .page 도메인 이용 시 유의 사항이 있나요?',
            typographies: [
                <Typography.Text><Typography.Text strong>.app / .dev / .page 도메인</Typography.Text>은 기본적으로 HTTP 접속이 불가하고, <Typography.Text strong>HTTPS 접속만 가능</Typography.Text>합 니다. </Typography.Text>,
                <Typography.Text>따라서.app / .dev / .page 도메인을 연결한 웹사이트를 정상적으로 운영하려면 SSL인 증서가 필수로 설치되어야 합니다.</Typography.Text>,
            ]
        },
        {
            type: '도메인',
            title: '한글 도메인과 영문 도메인은 서비스 이용에 차이가 있나요?',
            typographies: [
                <Typography.Text><Typography.Text strong>한글 도메인</Typography.Text>은 아래와 같이 <Typography.Text strong>서비스 신청이나 연결이 제한</Typography.Text>되는 경우가 있습니다.</Typography.Text>,
                <Typography.Text>1. 메일 상품, 홈페이지 빌더 신청이 불가합니다.</Typography.Text>,
                <Typography.Text>2. 블로그, 스마트 스토어 등 네이버 서비스로 IP 연결이 제한됩니다. (포워딩 연결 가능)</Typography.Text>,
                <Typography.Text>※ 위 2가지 경우를 제외하고는 한글 도메인과 영문 도메인은 서비스 이용에 차이가 없습니다.</Typography.Text>,
                <Typography.Text>※ 필요한 경우, 한글 도메인은 영문-숫자로 이루어진 코드(퓨니코드)로 변환하여 사용할 수 있습니다.</Typography.Text>,
            ]
        },
        {
            type: '도메인',
            title: '도메인 등록 후 별도 소유자 인증이 필요한가요?',
            typographies: [
                <Typography.Text><Typography.Text strong>.BLOG / .APP / .CLOUD / .WORK / .OOO / .WANG / .FASHION / .TRADE 도메인</Typography.Text>의 경우, <Typography.Text strong>등록 후 15일 이내에 소유자 메일로 인증 메일이 발송</Typography.Text>됩니다. 해당 메일을 통해 인증을 완료해야 도메인 사용이 가능합니다. 만일 메일을 통해 소유자 인증을 하지 않는 경우, 도메인이 사용이 중지되므로 꼭 인증해 주세요.</Typography.Text>,
            ]
        },
    ]

    return (
        <Container menu={MenuType.QnA}>
            <Row className='qna-container'>
                <Col span={24} className='header'>
                    <LayoutContainer>
                        <Col span={12} style={{ alignSelf: 'center', marginTop: 100, }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`답답했던 상표의 모든 것!`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`전문가에게 물어보세요.`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Gray10, }}>{`Q&A`}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ marginTop: 40, alignSelf: 'end', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.qna}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 120, marginBottom: 120, }}>
                    <LayoutContainer style={{ justifyContent: 'space-between' }}>
                        <Col span={24} style={{ alignSelf: 'center', }}>
                            <Typography.Text style={{ fontSize: 28, fontWeight: 700, }}>{`자주 묻는 질문`}</Typography.Text>
                        </Col>
                        <Col span={24} style={{ alignSelf: 'center', marginTop: 10, }}>
                            <Space>
                                {types.map((t) => {
                                    return (
                                        <Typography.Link
                                            key={t}
                                            style={{
                                                fontSize: 16,
                                                color: t === type ? Color.Gray10 : Color.Blue60,
                                                borderWidth: 1,
                                                borderRadius: 20,
                                                borderStyle: 'solid',
                                                borderColor: Color.Blue60,
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                backgroundColor: t === type ? Color.Blue60 : Color.Transparent
                                            }}
                                            onClick={() => { setType(t) }}
                                        >
                                            {t}
                                        </Typography.Link>
                                    )
                                })}
                            </Space>
                        </Col>
                        <Col span={24} style={{ marginTop: 40, }}>
                            <Row gutter={[0, 30]}>
                                {contents
                                    .filter(c => type === '전체' ? true : c.type === type)
                                    .map((c, idx: number) => {
                                    return (
                                        <Col
                                            key={idx}
                                            span={24}
                                            style={{
                                                paddingTop: 30,
                                                paddingBottom: 30,
                                                paddingLeft: 30,
                                                paddingRight: 30,
                                                border: `1px solid ${Color.Gray50}`,
                                                borderRadius: 10,
                                            }}
                                        >
                                            <div>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 16,
                                                        color: Color.Gray10,
                                                        borderWidth: 1,
                                                        borderRadius: 4,
                                                        borderStyle: 'solid',
                                                        borderColor: Color.Blue60,
                                                        padding: '4px 10px',
                                                        backgroundColor: Color.Blue60,
                                                    }}
                                                >
                                                    {c.type}
                                                </Typography.Text>
                                            </div>
                                            <div>
                                                <Space direction='vertical' style={{ marginTop: 20, }}>
                                                    <Typography.Text strong style={{ fontSize: 20, }}>
                                                        {`Q. ${c.title}`}
                                                    </Typography.Text>
                                                </Space>
                                            </div>
                                            <div className='content'>
                                                <Space size={15} direction='vertical' style={{ marginTop: 10, }}>
                                                    {c.typographies.map((t: any, idx: number) => {
                                                        return t
                                                    })}
                                                </Space>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
            </Row>
        </Container>
    )
}