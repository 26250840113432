import { Col, Row, Space, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { AssetImage } from '../../assets/image/Index'
import { Color } from '../../styles/Color'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { NavigationType } from '../../types/NavigationType'
import { MenuType } from '../../types/MenuType'

export const 회사소개: FunctionComponent = () => {
    return (
        <Container type={NavigationType.White} menu={MenuType.회사소개}>
            <Row className='company-container'>
                <Col span={24} className='header'>
                    <LayoutContainer>
                        <Col span={12} style={{ alignSelf: 'center', marginTop: 100, }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, }}>{`나혼자상표는 언제나`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, }}>{`노력하고 있습니다`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Typography.Text style={{ fontSize: 20, fontWeight: 700, }}>{`모두를 위한 서비스, 나혼자상표`}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ marginTop: 40, alignSelf: 'end', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.company.people}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 120, }}>
                    <LayoutContainer style={{ justifyContent: 'space-between' }}>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.company.left}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50 }}>{`우리의 핵심 가치`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`사회 가치를 생각하는`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`나혼자상표`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, }}>{`상표에 대한 이해 부족으로 발생하는 이슈를`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 20, }}>{`해결하여 사업 번창을 도와드립니다.`}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    <LayoutContainer style={{ justifyContent: 'space-between', marginTop: 60, }}>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50 }}>{`우리가 나아갈 길`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`모두가 함께 누릴 수 있는`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`상표 권리를 위해`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, }}>{`AI를 기반으로 누구나 쉽게 상표와 도메인을`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 20, }}>{`등록할 수 있는 서비스를 실현해 나갑니다.`}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.company.right}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 160, backgroundColor: Color.Gray80, paddingTop: 100, paddingBottom: 100, }}>
                    <LayoutContainer>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space direction='vertical'>
                                <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50, }}>One Stop</Typography.Text>
                                <Typography.Text style={{ fontSize: 28, fontWeight: 700, color: Color.Gray10, }}>전문가들과 함께하는 브랜드 제작 서비스</Typography.Text>
                            </Space>
                        </Col>
                        <Col span={24} style={{ marginTop: 40, }}>
                            <Row justify={'space-between'}>
                                <Col span={6}><img src={AssetImage.background.company.considerate} alt='considerate' width={'100%'} /></Col>
                                <Col span={6}><img src={AssetImage.background.company.effective} alt='effective' width={'100%'} /></Col>
                                <Col span={6}><img src={AssetImage.background.company.reliable} alt='reliable' width={'100%'} /></Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ paddingTop: 100, paddingBottom: 100, }}>
                    <LayoutContainer>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50, }}>Partnership</Typography.Text>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center', marginTop: 20, }}>
                            <Space size={0} direction='vertical'>
                                <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>다양한 파트너사들이</Typography.Text>
                                <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>나혼자상표와 함께합니다.</Typography.Text>
                            </Space>
                        </Col>
                        <Col span={24} style={{ marginTop: 40, alignSelf: 'center' }}>
                            <Row justify={'center'}>
                                <Col span={12}>
                                    <img src={AssetImage.background.company.협력사} alt='considerate' width={'100%'} />
                                </Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
            </Row>
        </Container>
    )
}