import { Button, Col, Form, Modal, Row, Space, Typography } from "antd"
import { Fragment, FunctionComponent, useEffect } from "react"
import { TrademarkModel } from "../../models/TrademarkModel"
import { InputForm } from "../form/InputForm"

interface IProps {
    open: boolean
    담당자?: TrademarkModel.ITrademarkModel담당자Model
    onPress: (담당자: TrademarkModel.ITrademarkModel담당자Model,) => void
    onClosed: () => void
}

export const 담당자정보입력Modal: FunctionComponent<IProps> = (props) => {
    const {
        open,
        담당자,
        onPress,
        onClosed,
    } = props

    const [form] = Form.useForm()

    const Footer = () => {
        return (
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Space>
                        <Button
                            type="default"
                            onClick={onClosed}
                        >
                            취소
                        </Button>
                        <Button
                            className="primary small"
                            onClick={() => {
                                form.validateFields()
                                    .then(() => {
                                        onPress(form.getFieldsValue())
                                    })
                                    .catch((e) => {
                                        alert('필수 정보를 입력해주세요')
                                        return
                                    })
                            }}
                        >
                            등록
                        </Button>
                    </Space>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if(담당자) {
            form.setFieldValue('fullName', 담당자?.fullName)
            form.setFieldValue('email', 담당자?.email)
            form.setFieldValue('phoneNumber', 담당자?.phoneNumber)
            form.setFieldValue('telephoneNumber', 담당자?.telephoneNumber)
        }
    }, [담당자])

    return (
        <Modal
            open={open}
            destroyOnClose={true}
            title={'담당자 정보'}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Form form={form}>
                <Row gutter={[12, 6]} style={{ marginTop: 40, }}>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 담당자 명`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='fullName'
                                placeholder='담당자 이름을 입력하세요'
                                rules={[{ required: true, message: "담당자 이름을 입력하세요" }]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 담당자 이메일`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='email'
                                placeholder='담당자 이메일을 입력하세요'
                                rules={[
                                    { required: true, message: "담당자 이메일을 입력하세요" },
                                    {
                                        pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                        message: '이메일을 정확하게 입력해주세요'
                                    },
                                ]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 담당자 휴대전화`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='phoneNumber'
                                placeholder='담당자 휴대전화를 입력하세요'
                                rules={[
                                    { required: true, message: "담당자 휴대전화를 입력하세요" },
                                    // {
                                    //     pattern: /^[0-9]*$/,
                                    //     message: "담당자 휴대전화를 숫자만 입력하세요"
                                    // }
                                ]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`담당자 유선전화`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='telephoneNumber'
                                placeholder='담당자 유선전화를 입력하세요'
                                rules={[
                                    // { required: true, message: "담당자 유선전화를 입력하세요" },
                                    // {
                                    //     pattern: /^[0-9]*$/,
                                    //     message: "담당자 유선전화를 숫자만 입력하세요"
                                    // }
                                ]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}