import { FunctionComponent, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import LocalStorageUtility from '../../utils/LocalStorageUtility'
import { message } from 'antd'

export const KakaoOAuth: FunctionComponent = () => {
    const [params] = useSearchParams()

    useEffect(() => {
        const isProduction = process.env.NODE_ENV === 'production'
        const url = isProduction ? 'https://markerslab.app' : 'http://localhost:3000'
        const code = params.get('code')

        if (code) {
            var details = {
                'grant_type': 'authorization_code',
                'client_id': '543124fe7c930400693b5f3caf0caf87',
                'code': code,
                'redirect_uri': `${url}/kakao/oauth`
            };

            let formBody = [
                `${encodeURIComponent('grant_type')}=${encodeURIComponent(details.grant_type)}`,
                `${encodeURIComponent('client_id')}=${encodeURIComponent(details.client_id)}`,
                `${encodeURIComponent('code')}=${encodeURIComponent(details.code)}`,
                `${encodeURIComponent('redirect_uri')}=${encodeURIComponent(details.redirect_uri)}`,
            ]
            const body = formBody.join("&");

            fetch('https://kauth.kakao.com/oauth/token',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                    },
                    body: body,
                }
            )
                .then((res) => {
                    return res.json()
                })
                .then((res: { access_token: string }) => {
                    fetch('https://api.markerslab.app/auth/kakao',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                accessToken: res.access_token
                            })
                        })
                        .then(res => {
                            if(res.status != 200) {
                                message.error('로그인에 실패했습니다!', 2, () => {
                                     window.location.href = '/login'
                                })
                                return
                            }

                            return res.json()
                        })
                        .then((json) => {
                            LocalStorageUtility.set('user', json)
                            window.location.href = '/'
                        })
                        .catch((e) => {
                            console.log('error', e)
                        })

                })
                .catch((err) => {
                    console.log('err', err)
                })
        }
    }, [])

    return (
        <></>
    )
}