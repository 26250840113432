import { Button, Col, Divider, Modal, Row, Space, Spin, Typography, UploadFile } from "antd"
import { UploadChangeParam } from "antd/es/upload"
import { FunctionComponent, useEffect, useState } from "react"
import { AssetImage } from "../../assets/image/Index"
import { Vector } from "../../assets/svg/Index"
import { ImageModel } from "../../models/ImageModel"
import { TrademarkModel } from "../../models/TrademarkModel"
import { Color } from "../../styles/Color"
import DateUtility from "../../utils/DateUtility"
import { SingleImageUploadForm } from "../form/SingleImageUploadForm"

interface IProps {
    open: boolean
    출원인: TrademarkModel.ITrademarkModel출원인Model
    stamp?: ImageModel.IStampImageModel
    onPress: (image: ImageModel.IStampImageModel) => void
    onClosed: () => void
}

export const 결제동의Modal: FunctionComponent<IProps> = (props) => {
    const { open, 출원인, stamp, onPress, onClosed, } = props

    const [isLoading, setLoading] = useState(false)
    const [image, setImage] = useState<ImageModel.IStampImageModel | undefined>(stamp)

    const item = (header: string, items?: Array<{ title?: string, content?: string }>) => {
        return (
            <Row style={{ marginBottom: 15 }}>
                <Col span={24} style={{ marginBottom: 10 }}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 18,
                        }}>
                        {header}
                    </Typography.Text>
                </Col>
                {items?.map((item, idx: number) => {
                    return (
                        <Col span={24} key={idx}>
                            <Row
                                justify={'space-between'}
                                style={{ marginBottom: 5, }}
                            >
                                {item.title && (
                                    <Col span={12}>
                                        <Typography.Text
                                            style={{
                                                fontSize: 18,
                                            }}>
                                            {item.title}
                                        </Typography.Text>
                                    </Col>
                                )}
                                {item.content && (
                                    <Col span={12} style={{ textAlign: 'end' }}>
                                        <Typography.Text
                                            style={{
                                                fontSize: 18,
                                            }}>
                                            {item.content}
                                        </Typography.Text>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const notification = (title: string) => {
        return (
            <Col style={{ marginBottom: 5 }}>
                <Typography.Text
                    style={{
                        fontSize: 18,
                    }}>
                    {title}
                </Typography.Text>
            </Col>
        )
    }

    useEffect(() => {
        if (stamp) {
            setImage(stamp)
        }
    }, [stamp])

    const Footer = () => {
        return (
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Space>
                        <Button
                            type="default"
                            onClick={onClosed}
                        >
                            취소
                        </Button>
                        <Button
                            disabled={!image}
                            className="primary small"
                            onClick={() => {
                                if (image) {
                                    onPress(image)
                                }
                            }}
                        >
                            동의
                        </Button>
                    </Space>
                </Col>
            </Row>
        )
    }
    return (
        <Modal
            open={open}
            width={600}
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 24,
                        }}
                    >
                        {`위임장`}
                    </Typography.Text>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {item('[수임자]', [
                        { title: '대리인 성명(명칭)', content: '변리사 황수민' },
                        { title: '대리인 번호', content: '9-2019-000163-6' },
                    ])}
                    {item('[수임자]', [
                        { title: '대리인 성명(명칭)', content: '변리사 강신영' },
                        { title: '대리인 번호', content: '9-2017-001350-9' },
                    ])}
                    {/* {item('[사건의 표시]', [
                                { title: '상품류', content: 'OOOOOO' },
                                { title: '상품분류', content: 'OOO OOO OOOOOO' },
                            ])} */}
                    {item('[위임자]', [
                        { title: '성명(명칭)', content: 출원인?.koreanFullName },
                        { title: '특허고객번호', content: 출원인?.applicantCode },
                        { title: '사건과의 관계', content: '출원인' },
                    ])}
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24}>
                    <Col style={{ marginBottom: 20 }}>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 18,
                            }}
                        >
                            {`[위임사항]`}
                        </Typography.Text>
                    </Col>
                    {notification('1. 상표등록출원에 관한 모든 절차')}
                    {notification('2. 상표권 등록에 관한 모든 절차')}
                    {notification('3. 상표등록출원에 관한 거절결정불복심판청구 관련 모든 절차')}
                    {notification('4. 상표등록출원에 관한 보정각하결정불복심판청구 관련 모든 절차')}
                    {notification('5. 타인의 상표등록출원에 관한 「상표법」 제49조 규정에 의한 정보의 제공')}
                    {notification('6. 상품분류전환등록신청에 관한 모든 절차')}
                    {notification('7. 상품분류전환등록에 관한 모든 절차')}
                    {notification('8. 상품분류전환등록신청에 관한 거절결정불복심판청구 관련 모든 절차')}
                    {notification('9. 상품분류전환등록신청에 관한 보정각하결정불복심판 관련 모든 절차')}
                    {notification('10. 상표등록출원이의신청에 관한 절차')}
                    {notification('11. 상표등록심판에 관한 모든 절차')}
                </Col>
            </Row>
            <Row style={{ marginTop: 20, }}>
                <Col span={24}>
                    {item('[위임일자]', [
                        { title: ' ', content: DateUtility.date_format() },
                    ])}
                </Col>
            </Row>
            <Row gutter={[20, 20]} style={{ marginTop: 20, }}>
                <Col span={24}>
                    <Typography.Text
                        style={{
                            fontSize: 18,
                        }}
                    >
                        {'위와 같이 특허청장(특허심판원장, 심판장)에게 제출합니다.'}
                    </Typography.Text>
                </Col>
                <Col span={24} style={{ textAlign: 'end', }}>
                    <Typography.Text
                        style={{
                            fontSize: 18,
                        }}
                    >
                        {`위임자 :      ${출원인?.koreanFullName ?? ''}    (인)`}
                    </Typography.Text>
                    {image && (
                        <div
                            style={{
                                position: 'absolute',
                                top: -2,
                                right: 10,
                                zIndex: 1,
                                height: 30,
                                width: 30,
                                backgroundColor: Color.Gray20
                            }}
                        >
                            <img
                                src={image.backgroundRemoval}
                                style={{
                                    width: '100%',
                                    height: 30,
                                    borderRadius: 8,
                                    objectFit: 'contain'
                                }}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <Row style={{ marginTop: 20, }}>
                <Col span={24} style={{ marginBottom: 10, }}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 18,
                        }}>
                        {'[위임 동의를 위한 도장 업로드]'}
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text
                            style={{
                                fontSize: 18,
                            }}
                        >
                            {'도장 이미지는 자동으로 배경이 제거됩니다.'}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: 18,
                            }}
                        >
                            {'도장을 화면 가운데 놓고 깔끔한 환경에서 촬영해 주세요.'}
                        </Typography.Text>
                    </Space>
                </Col>
                <Col span={24} style={{ textAlign: 'center', marginTop: 10, }}>
                    <img src={AssetImage.guide.stamp} alt='stamp' width={200} />
                </Col>
                <Col span={24} style={{ marginTop: 20, }}>
                    <SingleImageUploadForm
                        path={"https://api.markerslab.app/stamp"}
                        onUploaded={(uploadedFile: UploadChangeParam<UploadFile<any>>) => {
                            if (uploadedFile.file.response?.stampId) {
                                setImage({
                                    stampId: uploadedFile.file.response?.stampId,
                                    original: uploadedFile.file.response?.original,
                                    backgroundRemoval: uploadedFile.file.response?.backgroundRemoval,
                                })
                                setLoading(false)
                            } else {
                                setLoading(true)
                            }
                        }}
                    >
                        <Spin spinning={isLoading}>
                            <Row>
                                <Col
                                    span={24}
                                    style={{
                                        height: 214,
                                        borderRadius: 20,
                                        borderWidth: 1,
                                        borderColor: Color.Gray50,
                                        borderStyle: 'solid',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Row justify={"center"} align={'middle'} style={{ height: '100%' }}>
                                        {!image && (
                                            <Col span={10} style={{ textAlign: 'center' }}>
                                                <Space direction='vertical'>
                                                    <img src={Vector.icon.camera.black} alt="camera" />
                                                    <Typography.Text
                                                        style={{
                                                            fontSize: 18,
                                                            color: Color.Gray60
                                                        }}
                                                    >
                                                        {'이미지를 업로드 해주세요'}
                                                    </Typography.Text>
                                                </Space>
                                            </Col>
                                        )}
                                        {image && (
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <img
                                                    src={image.backgroundRemoval}
                                                    alt={'stamp'}
                                                    height={'100%'}
                                                    width={'100%'}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Spin>
                    </SingleImageUploadForm>
                </Col>
            </Row>
        </Modal >
    )
}