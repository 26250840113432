import { Col, Form, Row, Space, Typography, notification } from 'antd'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Slider, { Settings } from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { AssetImage } from '../assets/image/Index'
import { Vector } from '../assets/svg/Index'
import { InputForm } from '../components/form/InputForm'
import { Color } from '../styles/Color'
import { 상표검색타입 } from '../types/상표검색타입'
import { 앱다운로드 } from './etc/앱다운로드'
import { Container } from './layout/Container'
import { LayoutContainer } from './layout/LayoutContainer'

type 홈메뉴 = '상표명' | '도메인'

export const Home: FunctionComponent = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [메뉴, set메뉴] = useState<홈메뉴>('상표명')

    const sliderRef = useRef<Slider>()
    const settings: Settings = {
        infinite: true,
        speed: 600,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.2,
                },
            },
        ],
    }

    useEffect(() => {
    }, [])

    return (
        <Container>
            <Row className='home-container'>
                <Col span={24} className='header'>
                    <Form form={form}>
                        {메뉴 === '상표명' && (
                            <상표검색
                                onSearch={() => {
                                    const keyword = form.getFieldValue('keyword')
                                    if (keyword?.trim().length > 0) {
                                        navigate('/search/trademarks', { state: { keyword: keyword, searchType: 상표검색타입.타입.All } })
                                    }
                                }}
                                onClick={(menu: 홈메뉴) => { set메뉴(menu) }} />
                        )}
                        {메뉴 === '도메인' && (
                            <도메인검색
                                onSearch={() => {
                                    const keyword = form.getFieldValue('keyword')
                                    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(keyword)) {
                                        notification.error({
                                            message: '도메인은 영어로만 입력해주세요',
                                        })
                                        return
                                    }

                                    if (keyword?.trim().length > 0) {
                                        navigate('/search/domain', { state: { keyword: keyword } })
                                    }
                                }}
                                onClick={(menu: 홈메뉴) => { set메뉴(menu) }}
                            />
                        )}
                    </Form>
                </Col>
                <Col span={24} style={{ marginTop: 80, }}>
                    <LayoutContainer>
                        <Link to={'/create/trademarks'}>
                            <Col span={24}>
                                <Space size={20}>
                                    <Typography.Text style={{ fontSize: 28, fontWeight: 700, }}>{`원하시는 상표를 등록해 보세요`}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 24, position: 'absolute', top: 4, }}>{`ᐳ`}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Typography.Text style={{ fontSize: 18, }}>등록하실 상표를 검색하고, 등록 가능성을 실시간으로 조회해 보세요!</Typography.Text>
                            </Col>
                            <Col span={24} style={{ marginTop: 40, }}>
                                <img
                                    width={'100%'}
                                    src={AssetImage.banner.home.전문가}
                                    alt='상표출원을 전문가가 도와드려요' />
                            </Col>
                        </Link>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 80, }}>
                    <LayoutContainer>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Typography.Text style={{ fontSize: 28, fontWeight: 700 }}>나혼자상표의 다양한 상표 출원 방법!</Typography.Text>
                        </Col>
                        <Col span={24} style={{ marginTop: 40, }}>
                            <Row>
                                <Col span={6}><img src={AssetImage.banner.home.바로출원} alt='바로출원' width={'100%'} /></Col>
                                <Col span={6}><img src={AssetImage.banner.home.간편출원} alt='간편출원' width={'100%'} /></Col>
                                <Col span={6}><img src={AssetImage.banner.home.직접출원} alt='직접출원' width={'100%'} /></Col>
                                <Col span={6}><img src={AssetImage.banner.home.심화출원} alt='심화출원' width={'100%'} /></Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 60, }}>
                    <LayoutContainer>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Link to={'/process'}>
                                <img src={AssetImage.banner.home.상표등록절차} alt='상표등록절차' width={'100%'} />
                            </Link>
                        </Col>
                    </LayoutContainer>
                </Col>
                {/* <Col span={24} style={{ marginTop: 80, }}>
                    <LayoutContainer>
                        <Col span={24}>
                            <Typography.Text style={{ fontSize: 28, fontWeight: 700, }}>{`나혼자상표에 등록된 상표입니다.`}</Typography.Text>
                        </Col>
                        <Col span={24} style={{ marginTop: 40, }}>
                            <Slider ref={sliderRef as LegacyRef<Slider>} {...settings}>
                                {images.map((d, idx: number) => {
                                    return (
                                        <Row key={idx} gutter={[40, 0]} >
                                            <Col>
                                                <img width={'100%'} src={d} alt={'test'} />
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Slider>
                        </Col>
                    </LayoutContainer>
                </Col> */}
                <Col
                    span={24}
                    style={{
                        marginTop: 80,
                        backgroundColor: Color.Blue80,
                        paddingTop: 50,
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}>
                    <앱다운로드 />
                </Col>
            </Row>
        </Container>
    )
}

const 상표검색 = (props: { onSearch: () => void, onClick: (menu: 홈메뉴) => void }) => {
    const { onSearch, onClick } = props
    return (
        <LayoutContainer style={{ marginTop: 200 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Text style={{ fontSize: 40, color: '#fff', fontWeight: 700, }}>출원하시는</Typography.Text>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Text style={{ fontSize: 40, color: '#fff', fontWeight: 700, }}>상표를 검색해 보세요!</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 50, textAlign: 'center', }}>
                <Space size={40}>
                    <Typography.Link
                        style={{
                            fontSize: 24,
                            fontWeight: 700,
                            color: '#fff',
                            paddingBottom: 6,
                            borderBottomWidth: 2,
                            borderColor: '#fff',
                            borderBottomStyle: 'solid',
                        }}
                        onClick={() => { onClick('상표명') }}
                    >
                        상표명
                    </Typography.Link>
                    <Typography.Link
                        style={{ fontSize: 24, color: '#fff' }}
                        onClick={() => { onClick('도메인') }}
                    >
                        도메인
                    </Typography.Link>
                </Space>
            </Col>
            <Col span={24} style={{ marginTop: 30, }}>
                <Row justify={'center'}>
                    <Col span={12}>
                        <InputForm
                            name='keyword'
                            placeholder='상표명을 입력하세요'
                            style={{
                                height: 50,
                                borderRadius: 30,
                                fontSize: 16,
                                paddingLeft: 30,
                            }}
                            layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            onPressEnter={onSearch}
                        />
                        <Typography.Link onClick={onSearch}>
                            <img
                                src={Vector.icon.search.gray} width={30}
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 20,
                                    zIndex: 10,
                                }}
                            />
                        </Typography.Link>
                    </Col>
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: 10, textAlign: 'center' }}>
                <Typography.Text style={{ fontSize: 18, color: Color.Gray60 }}>특허청 KIPRIS 상표 데이터와 실시간 연동됩니다.</Typography.Text>
            </Col>
        </LayoutContainer>
    )
}

const 도메인검색 = (props: { onSearch: () => void, onClick: (menu: 홈메뉴) => void }) => {
    const { onSearch, onClick } = props

    return (
        <LayoutContainer style={{ marginTop: 200 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Text style={{ fontSize: 40, color: '#fff', fontWeight: 700, }}>원하시는</Typography.Text>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Text style={{ fontSize: 40, color: '#fff', fontWeight: 700, }}>도메인을 검색해 보세요!</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 50, textAlign: 'center', }}>
                <Space size={40}>
                    <Typography.Link
                        style={{ fontSize: 24, color: '#fff' }}
                        onClick={() => { onClick('상표명') }}
                    >
                        상표명
                    </Typography.Link>
                    <Typography.Link
                        style={{
                            fontSize: 24,
                            fontWeight: 700,
                            color: '#fff',
                            paddingBottom: 6,
                            borderBottomWidth: 2,
                            borderColor: '#fff',
                            borderBottomStyle: 'solid',
                        }}
                        onClick={() => { onClick('도메인') }}
                    >
                        도메인
                    </Typography.Link>
                </Space>
            </Col>
            <Col span={24} style={{ marginTop: 30, }}>
                <Row justify={'center'}>
                    <Col span={12}>
                        <Typography.Text
                            style={{
                                position: 'absolute',
                                top: 9,
                                left: 20,
                                zIndex: 10,
                                fontSize: 20,
                            }}
                        >
                            WWW.
                        </Typography.Text>
                        <InputForm
                            name='keyword'
                            placeholder='도메인을 입력하세요'
                            style={{
                                height: 50,
                                borderRadius: 30,
                                fontSize: 16,
                                paddingLeft: 90,
                            }}
                            layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            onPressEnter={onSearch}
                        />
                        <Typography.Link onClick={onSearch}>
                            <img
                                src={Vector.icon.search.gray} width={30}
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 20,
                                    zIndex: 10,
                                }}
                            />
                        </Typography.Link>
                    </Col>
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: 10, textAlign: 'center' }}>
                <Typography.Text style={{ fontSize: 18, color: Color.Gray60 }}>도메인 서비스사와 실시간 연동됩니다.</Typography.Text>
            </Col>
        </LayoutContainer>
    )
}