
import { Button, Col, Divider, Form, Row, Space, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Vector } from '../../assets/svg/Index'
import { OrderBindingModel } from '../../bindings/OrderBindingModel'
import { 결제동의Modal } from '../../components/modal/결제동의Modal'
import { 담당자정보입력Modal } from '../../components/modal/담당자정보입력Modal'
import { 출원자정보입력Modal } from '../../components/modal/출원자정보입력Modal'
import { 환불정책Modal } from '../../components/modal/환불정책Modal'
import { CategoryModel } from '../../models/CategoryModel'
import { ImageModel } from '../../models/ImageModel'
import { OrderModel } from '../../models/OrderModel'
import { TrademarkModel } from '../../models/TrademarkModel'
import { CategoryService } from '../../services/CategoryService'
import { OrderService } from '../../services/OrderService'
import { Color } from '../../styles/Color'
import { MenuType } from '../../types/MenuType'
import { NavigationType } from '../../types/NavigationType'
import { 출원방법타입 } from '../../types/출원방법타입'
import LocalStorageUtility from '../../utils/LocalStorageUtility'
import NumberUtility from '../../utils/NumberUtility'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { 상표결제Item } from './상표결제Item'

export const 상표결제: FunctionComponent = () => {
    const location = useLocation()
    const { id } = useParams()

    const { state } = location

    if (!state && !id) {
        window.location.href = '/'
    }

    const { type, name, alphabet, description, codes } = state as {
        type: 출원방법타입.출원방법,
        name: string,
        alphabet?: string,
        description: string,
        codes: Array<string>
    }

    const [show담당자정보입력Modal, setShow담당자정보입력Modal] = useState(false)
    const [show출원자정보입력Modal, setShow출원자정보입력Modal] = useState(false)
    const [show결제동의Modal, setShow결제동의Modal] = useState(false)
    const [show환불정책Modal, setShow환불정책Modal] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [hasAgree, setHasAgree] = useState<boolean>(false)
    const [image, setImage] = useState<ImageModel.IStampImageModel>()
    const [담당자, set담당자] = useState<TrademarkModel.ITrademarkModel담당자Model>()
    const [출원인, set출원인] = useState<TrademarkModel.ITrademarkModel출원인Model>()

    const [items, setItems] = useState<Array<TrademarkModel.ITrademarkPaymentItemModel>>(codes.map((c) => {
        return {
            classificationCode: c,
            commissionType: type,
            isQuick: false,
        }
    }))
    const [v2Categories, setV2Categories] = useState<Array<CategoryModel.ITrademarkCategoryV12Model>>([])

    const [form] = Form.useForm()

    const getV12 = async () => {
        setLoading(true)
        const response = await CategoryService.v12(codes)
        if (response.status === 200) {
            setV2Categories(response.data)
        }
        setLoading(false)
    }

    const handleOrder = async () => {
        setLoading(true)

        const model: OrderBindingModel.ICreateOrderBindingModel = {
            operator: 담당자!,
            applicant: 출원인!,
            koreanTrademarkName: name,
            englishTrademarkName: alphabet,
            description: description,
            stampId: image?.stampId!,
            items: items.map((i) => {
                return {
                    classificationCode: i.classificationCode,
                    // checkPoint: point,
                    commissionType: i.commissionType,
                    isQuick: i.isQuick
                }
            })
        }

        const response = await OrderService.create(model)
        if (response.status === 200) {
            const orderId = response.data.orderId
            LocalStorageUtility.set(orderId, {
                ...model,
                type: type,
                codes: codes,
                stamp: image
            } as OrderModel.IOrderBackupModel)

            setTimeout(() => {
                const isProduction = process.env.NODE_ENV === 'production'

                const successUrl = isProduction ? `https://markerslab.app/payment/success/${orderId}` : `http://localhost:3000/payment/success/${orderId}`
                const failUrl = isProduction ? `https://markerslab.app/payment/fail/${orderId}` : `http://localhost:3000/payment/fail/${orderId}`

                window.location.href = `https://payment.markerslab.app/?orderid=${orderId}&successRedirectUrl=${encodeURIComponent(successUrl)}&failureRedirectUrl=${encodeURIComponent(failUrl)}`
            }, 1000)
        }
        setLoading(false)
    }

    const 관납료 = () => {
        const count = items.filter(i => !i.isQuick).length ?? 0
        const quickCount = items.filter(i => i.isQuick).length ?? 0

        const 일반심사_관납료 = 46000 * count
        const 우선심사_관납료 = (160000 + 46000) * quickCount

        return Math.round(일반심사_관납료 + 우선심사_관납료)
    }

    const 수수료 = () => {
        let price = 0
        const count = items.filter(i => !i.isQuick).length ?? 0
        const quickCount = items.filter(i => i.isQuick).length ?? 0

        switch (type) {
            case 출원방법타입.출원방법.바로출원:
                price = 18000
                break
            case 출원방법타입.출원방법.간편출원:
                price = 48000
                break
            case 출원방법타입.출원방법.심화출원:
                price = 88000
                break
        }

        const 일반심사_나혼자상표_수수료 = price * count
        const 우선심사_나혼자상표_수수료 = (145000 + price) * quickCount

        return Math.round(일반심사_나혼자상표_수수료 + 우선심사_나혼자상표_수수료)
    }

    useEffect(() => {
        const userData = LocalStorageUtility.get('user')
        if (!userData) {
            alert('로그인 후 진행해주세요.')
            window.location.href = '/login'
        }

        if (id) {
            const orderData = LocalStorageUtility.get(id)
            if (orderData) {
                const order = JSON.parse(orderData) as OrderModel.IOrderBackupModel

                set담당자(order?.operator)
                set출원인(order?.applicant)
                if (order.stamp) {
                    setImage(order.stamp)
                }
            }
        }

        getV12()
    }, [])

    return (
        <Container type={NavigationType.White} menu={MenuType.상표등록}>
            <Row className="payment-trademark-container">
                <Col span={24}>
                    <LayoutContainer style={{ gap: 20, }}>
                        <Col span={24}>
                            <Space direction="vertical">
                                <Typography.Text
                                    strong
                                    style={{
                                        fontSize: 20,
                                    }}
                                >
                                    {`${name} ${alphabet ? `(${alphabet})` : ''}`}
                                </Typography.Text>
                                <Space size={1}>
                                    {codes.map((c, idx: number) => {
                                        return (
                                            <Typography.Text
                                                key={idx}
                                                style={{
                                                    fontSize: 18,
                                                }}
                                            >
                                                {`${c}류${idx === (codes.length - 1) ? '' : ', '}`}
                                            </Typography.Text>
                                        )
                                    })}
                                </Space>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    <LayoutContainer style={{ gap: 30, }}>
                        <Col span={24}>
                            <Row gutter={[0, 10]}>
                                <Col span={24}>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 20,
                                        }}
                                    >
                                        {`[필수] 담당자 정보`}
                                    </Typography.Text>
                                </Col>
                                {!담당자 && (
                                    <Col span={12}>
                                        <Button
                                            block
                                            className='secondary'
                                            onClick={() => { setShow담당자정보입력Modal(true) }}
                                        >
                                            담당자 정보 입력
                                        </Button>
                                    </Col>
                                )}
                                {담당자 && (
                                    <Col span={24}>
                                        <Typography.Link
                                            onClick={() => { setShow담당자정보입력Modal(true) }}
                                        >
                                            <Row>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'담당자 이름'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {담당자?.fullName}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'담당자 이메일'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {담당자?.email}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'담당자 휴대전화'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {담당자?.phoneNumber}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'담당자 유선전화'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {담당자?.telephoneNumber}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Typography.Link>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[0, 20]}>
                                <Col span={24}>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 20,
                                        }}
                                    >
                                        {`[필수] 출원인 정보`}
                                    </Typography.Text>
                                </Col>
                                {!출원인 && (
                                    <Col span={12}>
                                        <Button
                                            block
                                            className='secondary'
                                            onClick={() => { setShow출원자정보입력Modal(true) }}
                                        >
                                            출원인 정보 입력
                                        </Button>
                                    </Col>
                                )}
                                {출원인 && (
                                    <Col span={24}>
                                        <Typography.Link
                                            onClick={() => { setShow출원자정보입력Modal(true) }}
                                        >
                                            <Row>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'출원인 이름(한글)'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {출원인.koreanFullName}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'출원인 이름(영문)'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {출원인.englishFullName}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'출원인 특허고객번호'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {출원인.applicantCode}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'출원인 이메일'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {출원인.email}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row justify={'space-between'}>
                                                        <Col>
                                                            <Typography.Text
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {'출원인 휴대전화'}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Text
                                                                strong
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {출원인.phoneNumber}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Typography.Link>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    <LayoutContainer style={{ gap: 20, justifyContent: 'center', }}>
                        <Col span={24}>
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 22,
                                }}
                            >
                                {`출원 방법 선택`}
                            </Typography.Text>
                        </Col>
                        {codes.map((c) => {
                            const isActivated = items.some(i => i.classificationCode === c)
                            const isSwitchActivated = items.some(i => i.classificationCode === c && i.isQuick)
                            const v2 = v2Categories.find(v => v.classificationCode === c)

                            return (
                                <상표결제Item
                                    key={c}
                                    code={c}
                                    type={type}
                                    description={v2?.description}
                                    isActivated={isActivated}
                                    isSwitchActivated={isSwitchActivated}
                                    onPress={() => {
                                        if (isActivated) {
                                            setItems([...items.filter(i => i.classificationCode !== c)])
                                        } else {
                                            setItems([...items, {
                                                classificationCode: c,
                                                commissionType: type,
                                                isQuick: false,
                                            }])
                                        }
                                    }}
                                    onPressSwitch={() => {
                                        setItems([
                                            ...items.filter(i => i.classificationCode !== c),
                                            {
                                                classificationCode: c,
                                                commissionType: type,
                                                isQuick: !isSwitchActivated,
                                            }
                                        ])
                                    }}
                                />
                            )
                        })}
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    <LayoutContainer style={{ gap: 20, }}>
                        <Col span={24}>
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 20,
                                }}
                            >
                                {`위임 및 약관 동의`}
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Link
                                        onClick={() => {
                                            if (!출원인) {
                                                alert('출원인 정보를 입력하세요.')
                                                return
                                            }

                                            setShow결제동의Modal(true)
                                        }}
                                    >
                                        <Space>
                                            <img src={hasAgree ? Vector.check.blue : Vector.check.gray} />
                                            <Typography.Text style={{ fontSize: 16, color: Color.Gray80 }}>
                                                {'위임을 동의 합니다'}
                                            </Typography.Text>
                                        </Space>
                                    </Typography.Link>
                                </Col>
                                <Col>
                                    <Typography.Link
                                        style={{
                                            fontSize: 16,
                                            color: Color.Gray50,
                                        }}
                                        href={'/views/privacy-term'}
                                        target="_blank"
                                    >
                                        보기
                                    </Typography.Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    <LayoutContainer style={{ gap: 10, }}>
                        <Col span={24}>
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 20,
                                }}
                            >
                                {`결제 금액`}
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Link
                                        onClick={() => {
                                            setShow환불정책Modal(true)
                                        }}
                                    >
                                        <Space>
                                            <Typography.Text style={{ fontSize: 18 }}>
                                                {'환불 정책'}
                                            </Typography.Text>
                                        </Space>
                                    </Typography.Link>
                                </Col>
                                <Col>
                                    <Typography.Link
                                        strong
                                        style={{
                                            fontSize: 18,
                                            color: Color.Gray50,
                                        }}
                                        onClick={() => {
                                            setShow환불정책Modal(true)
                                        }}
                                    >
                                        보기
                                    </Typography.Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Text
                                        style={{ fontSize: 18 }}
                                    >
                                        {'특허청 관납료(VAT 제외)'}
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <Typography.Text
                                        strong
                                        style={{ fontSize: 18 }}
                                    >
                                        {`${NumberUtility.comma(관납료())} 원`}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Text
                                        style={{ fontSize: 18 }}
                                    >
                                        {'나혼자상표 수수료'}
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <Typography.Text
                                        strong
                                        style={{ fontSize: 18 }}
                                    >
                                        {`${NumberUtility.comma(수수료())} 원`}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Text
                                        style={{
                                            fontSize: 18,
                                            color: Color.Gray50,
                                        }}
                                    >
                                        {'VAT'}
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 18,
                                            color: Color.Gray50,
                                        }}
                                    >
                                        {`${NumberUtility.comma((수수료()) * 0.1)} 원`}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 18,
                                            color: Color.Blue70,
                                        }}
                                    >
                                        {'총 결제 금액'}
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 18,
                                            color: Color.Blue70,
                                        }}
                                    >
                                        {`${NumberUtility.comma(Math.round(수수료() * 1.1) + 관납료())} 원`}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={24}>
                            <Space direction='vertical'>
                                <Typography.Text style={{ fontSize: 16, }}>
                                    {'1. 특허청 관납료는 특허청 고시 명칭/지정 상품 20개 기준으로, 비고시 명칭 상품/지정 상품 20개 초과하면 '}
                                    <Typography.Text strong style={{ fontSize: 16, }}>
                                        {'추가 특허청 관납료가 발생해요.'}
                                    </Typography.Text>
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: 16, }}>
                                    {'2. 특허청 심사관의 거절로 인해 '}
                                    <Typography.Text strong style={{ fontSize: 16, }}>
                                        {'의견 대응이 발생'}
                                    </Typography.Text>
                                    {'하면 '}
                                    <Typography.Text strong style={{ fontSize: 16, }}>
                                        {'별도 비용이 추가'}
                                    </Typography.Text>
                                    {' 발생해요.'}
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: 16, }}>
                                    {'3. 특허청 심사를 통과하여 '}
                                    <Typography.Text strong style={{ fontSize: 16, }}>
                                        {'상표등록이 결정'}
                                    </Typography.Text>
                                    {'되면 '}
                                    <Typography.Text strong style={{ fontSize: 16, }}>
                                        {'등록료가 추가 발생'}
                                    </Typography.Text>
                                    {'해요.'}

                                </Typography.Text>
                            </Space>
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 60, }}>
                    <LayoutContainer style={{ justifyContent: 'center' }}>
                        <Col span={12}>
                            <Button
                                block
                                className='primary'
                                disabled={items.length <= 0 || !hasAgree || !담당자 || !출원인 || !image}
                                onClick={() => { handleOrder() }}
                            >
                                {`${(items.length) > 0 ? `${NumberUtility.comma(Math.round(수수료() * 1.1) + 관납료())} 원 결제하기` : `상품류를 선택해 주세요`}`}
                            </Button>
                        </Col>
                    </LayoutContainer>
                </Col>
            </Row>
            <결제동의Modal
                open={show결제동의Modal}
                출원인={출원인!}
                stamp={image}
                onClosed={() => {
                    setShow결제동의Modal(false)
                }}
                onPress={(image: ImageModel.IStampImageModel) => {
                    setHasAgree(true)
                    setShow결제동의Modal(false)
                    setImage(image)
                }}
            />
            <담당자정보입력Modal
                담당자={담당자}
                open={show담당자정보입력Modal}
                onPress={(담당자) => {
                    set담당자(담당자)
                    setShow담당자정보입력Modal(false)
                }}
                onClosed={() => {
                    setShow담당자정보입력Modal(false)
                }}
            />
            <출원자정보입력Modal
                담당자={담당자}
                출원인={출원인}
                open={show출원자정보입력Modal}
                onPress={(출원인) => {
                    set출원인(출원인)
                    setShow출원자정보입력Modal(false)
                }}
                onClosed={() => {
                    setShow출원자정보입력Modal(false)
                }}
            />
            <환불정책Modal
                open={show환불정책Modal}
                onClosed={() => {
                    setShow환불정책Modal(false)
                }}
            />
        </Container>
    )
}