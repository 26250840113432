
import { Button, Col, Form, Row, Space, Table, Tag, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AssetImage } from '../../assets/image/Index'
import { Vector } from '../../assets/svg/Index'
import { InputForm } from '../../components/form/InputForm'
import { PaginationModel } from '../../models/PaginationModel'
import { TrademarkModel } from '../../models/TrademarkModel'
import { TrademarkService } from '../../services/TrademarkService'
import { Color } from '../../styles/Color'
import { MenuType } from '../../types/MenuType'
import { 상표검색타입 } from '../../types/상표검색타입'
import DateUtility from '../../utils/DateUtility'
import NumberUtility from '../../utils/NumberUtility'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { 앱다운로드 } from '../etc/앱다운로드'

export const 상표검색결과: FunctionComponent = () => {
    const location = useLocation()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>(location.state?.keyword)
    const [type, setType] = useState<상표검색타입.타입>(location.state?.searchType ?? 상표검색타입.타입.All)
    const [pages, setPages] = useState<PaginationModel.IPaginationModel<TrademarkModel.ISearchTrademarkModel>>()
    const [items, setItems] = useState<Array<TrademarkModel.ISearchTrademarkModel>>([])

    const [form] = Form.useForm()

    const types = [상표검색타입.타입.All, 상표검색타입.타입.상표, 상표검색타입.타입.출원인]

    const getTrademarks = async (page: number = 0, keyword: string = location.state?.keyword) => {
        setLoading(true)
        const response = await TrademarkService.search(page, { mode: type, keyword: keyword })
        if (response.status === 200) {
            setPages(response.data)
            if(page == 0) {
                setItems([...response.data.items])
            } else {
                setItems([...items, ...response.data.items])
            }
            
        }
        setLoading(false)
    }

    useEffect(() => {
        form.setFieldValue('keyword', location.state?.keyword)

        getTrademarks()
    }, [])

    return (
        <Container menu={MenuType.상표검색}>
            <Row className='search-container'>
                <Col span={24} className='header'>
                    <LayoutContainer>
                        <Col span={24} style={{ textAlign: 'center', alignSelf: 'center' }}>
                            <Space size={40} split={'|'} style={{ color: Color.Gray10 }}>
                                {types.map((t, idx: number) => {
                                    return (
                                        <Typography.Link
                                            key={t}
                                            onClick={() => { setType(t) }}
                                        >
                                            <Typography.Text
                                                strong={t === type}
                                                style={{
                                                    fontSize: 26,
                                                    color: Color.Gray10,
                                                }}
                                            >
                                                {상표검색타입.displayName(t)}
                                            </Typography.Text>
                                        </Typography.Link>
                                    )
                                })}
                            </Space>
                            <Row justify={'center'} style={{ marginTop: 60, }}>
                                <Col span={12}>
                                    <Form form={form}>
                                        <InputForm
                                            name='keyword'
                                            placeholder='상표명을 입력하세요'
                                            style={{
                                                height: 50,
                                                borderRadius: 30,
                                                fontSize: 16,
                                                paddingLeft: 30,
                                            }}
                                            layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                                            onPressEnter={() => {
                                                const keyword = form.getFieldValue('keyword')
                                                if (!keyword || keyword.trim().length <= 0) {
                                                    alert('검색어를 입력하세요')
                                                    return
                                                }

                                                getTrademarks(0, keyword)
                                                setKeyword(keyword)
                                            }}
                                        />
                                    </Form>
                                    <Typography.Link
                                        onClick={() => {
                                            const keyword = form.getFieldValue('keyword')
                                            if (!keyword || keyword.trim().length <= 0) {
                                                alert('검색어를 입력하세요')
                                                return
                                            }

                                            getTrademarks(0, keyword)
                                            setKeyword(keyword)
                                        }}
                                    >
                                        <img
                                            src={Vector.icon.search.gray} width={30}
                                            style={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 20,
                                                zIndex: 10,
                                            }}
                                        />
                                    </Typography.Link>
                                </Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 60, paddingBottom: 80, }}>
                    <LayoutContainer style={{ marginTop: 30, }}>
                        <Col span={24}>
                            <Typography.Text
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                <Typography.Text
                                    strong
                                    style={{
                                        fontSize: 24,
                                    }}
                                >
                                    {`${keyword}`}
                                </Typography.Text>
                                {`와 관련된 상표권은 `}
                                <Typography.Text
                                    strong
                                    style={{
                                        fontSize: 24,
                                    }}
                                >
                                    {`${NumberUtility.comma(pages?.totalItemCount ?? 0)}`}
                                </Typography.Text>
                                <Typography.Text
                                    style={{
                                        fontSize: 24,
                                    }}
                                >
                                    {`건 입니다`}
                                </Typography.Text>
                            </Typography.Text>
                        </Col>
                        <Col span={24} style={{ alignSelf: 'center', }}>
                            <Table
                                rowKey={record => record.masterKey}
                                loading={isLoading}
                                pagination={false}
                                dataSource={items ?? []}
                                columns={[
                                    {
                                        title: "상표원본",
                                        dataIndex: "thumbnailUrl",
                                        key: "thumbnailUrl",
                                        align: 'center',
                                        render: (url: string) => {
                                            return (
                                                <img src={url} alt={'thumbnail'} />
                                            )
                                        },
                                    },
                                    {
                                        title: "상표명",
                                        dataIndex: "title",
                                        key: "title",
                                        align: 'center',
                                    },
                                    {
                                        title: "상품분류",
                                        dataIndex: "classificationCodes",
                                        key: "classificationCodes",
                                        align: 'center',
                                        render: codes => codes.join(', ')
                                    },
                                    {
                                        title: "출원인",
                                        dataIndex: "applicantName",
                                        key: "applicantName",
                                        align: 'center',
                                    },
                                    {
                                        title: "출원정보",
                                        dataIndex: "applicationStatus",
                                        key: "applicationStatus",
                                        align: 'center',
                                        render: (status) => {
                                            if (status === '등록') {
                                                return <Tag color={'blue'}>{status}</Tag>
                                            } else if (status === '무효') {
                                                return <Tag color={'yellow'}>{status}</Tag>
                                            } else if (status === '거절') {
                                                return <Tag color={'red'}>{status}</Tag>
                                            }

                                            return (
                                                <Tag>{status}</Tag>
                                            )
                                        }
                                    },
                                    {
                                        title: "출원일자",
                                        dataIndex: "registrationDate",
                                        key: "registrationDate", 
                                        render: (date: string) => {
                                            return (
                                                DateUtility.date_format(date)
                                            )
                                        },
                                        align: 'center',
                                    },
                                ]}
                            />
                        </Col>
                        {pages?.hasNextResult && (
                            <Col span={24} style={{ marginTop: 20, textAlign: 'center', }}>
                                <Button
                                    loading={isLoading}
                                    style={{ width: '50%', }}
                                    onClick={() => {
                                        getTrademarks(pages.pageIndex + 1, keyword)
                                    }}
                                >
                                    더보기
                                </Button>
                            </Col>
                        )}
                    </LayoutContainer>
                </Col>
                {/* <Col span={24} style={{ marginTop: 60, paddingTop: 40, paddingBottom: 40, }}>
                    <LayoutContainer style={{ justifyContent: 'center', }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Link to={''}>
                                <img width={'100%'} src={AssetImage.banner.상표검색.지정상품} alt={'banner'} />
                            </Link>
                        </Col>
                    </LayoutContainer>
                </Col> */}
                <Col
                    span={24}
                    style={{
                        marginTop: 30,
                        backgroundColor: Color.Blue80,
                        paddingTop: 50,
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}>
                    <앱다운로드 />
                </Col>
            </Row>
        </Container>
    )
}